<template>
  <router-view />
</template>

<script>
import NetteAjax from './nette/NetteAjax'

export default {
  mounted () {
    console.log('app mounted')
    NetteAjax.init()
    // handle clicks on link and pass to vue-router
    /*
    window.addEventListener('click', event => {
      // ensure we use the link, in case the click has been received by a subelement
      let { target } = event
      while (target && target.tagName !== 'A') target = target.parentNode
      // handle only links that do not reference external resources
      if (target && target.matches("a:not([href*='://'])") && target.href) {
        // some sanity checks taken from vue-router:
        // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
        const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event
        // don't handle with control keys
        if (metaKey || altKey || ctrlKey || shiftKey) return
        // don't handle when preventDefault called
        if (defaultPrevented) return
        // don't handle right clicks
        if (button !== undefined && button !== 0) return
        // don't handle if `target="_blank"`
        if (target && target.getAttribute) {
          const linkTarget = target.getAttribute('target')
          if (/\b_blank\b/i.test(linkTarget)) return
        }
        // don't handle same page links/anchors
        if (event.preventDefault) {
          event.preventDefault()
        }

        const NetteVueRouter = NetteAjax.ext('vue-router')
        NetteVueRouter.ui = target
        NetteVueRouter.e = event

        const route = NetteVueRouter.urlToRoute(target.href)
        const url = new URL(target.href)
        if (window.location.pathname !== route.path) {
          this.$router.push(route)
        } else if (window.location.search !== url.search) {
          this.$router.replace(route)
        }
      }
    })
    */
  }
}
</script>

<style lang="scss"></style>
