import axios from 'axios'

export default {
  install (Vue, options) {
    const defaults = {
      maxUploadFiles: null, // unlimited
      name: 'file', // file input name
      exts: null, // allowed file extensions
      url: null, // url to upload file
      highlightClass: 'hightlight',
      highlightErrorClass: 'hightlight-error',
      uploadProgress: [], // array of files progress
      onFileUploadDone (data, axiosResponse) {
        window.alert('You must set onFileUploadDone callback')
      },
      onError (error) {
        console.log('uppload error', error)
      },
      onProgress (progress) {
        console.log('uppload progress', progress)
      },
      onStart (files) {
        console.log('upload start with files', files)
      },
      onDone (files) {
        console.log('All files upload done')
      }
    }

    const errors = {
      maxUploadFiles: 'Max uploaded files limit exceeded',
      exts: 'File extensions %ext% is not allowed',
      upload: 'File upload failed'
    }

    const $http = axios.create({
      headers: { 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'multipart/form-data' }
    })

    const uploadFile = async (file, i, config) => {
      const { url, onFileUploadDone, onError, onProgress, name } = config
      const formData = new FormData()
      formData.append(name, file)
      let response = null

      try {
        response = await $http.post(url, formData, {
          onUploadProgress (progressEvent) {
            onProgress(updateProgress(i, (progressEvent.loaded * 100.0 / progressEvent.total) || 100, config))
          }
        })
        onFileUploadDone(response.data, response)
        return response
      } catch (error) {
        onError(error)
        throw error
      }
    }
    /*
    const previewFile = (file) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = function () {
        const img = document.createElement('img')
        img.src = reader.result
        document.getElementById('gallery').appendChild(img)
      }
    }
    */
    const initializeProgress = (numFiles, { uploadProgress }) => {
      uploadProgress = []

      for (let i = numFiles; i > 0; i--) {
        uploadProgress.push(0)
      }
    }

    const updateProgress = (fileNumber, percent, { uploadProgress }) => {
      uploadProgress[fileNumber] = percent
      const total = uploadProgress.reduce((tot, curr) => tot + curr, 0) / uploadProgress.length
      return {
        total,
        uploadProgress
      }
    }

    const createForm = (el, { maxUploadFiles }) => {
      const form = document.createElement('FORM')
      form.style.display = 'none'
      el.appendChild(form)

      const input = document.createElement('INPUT')
      input.type = 'file'

      if (maxUploadFiles !== 1) {
        input.setAttribute('multiple', 'multiple')
      }

      form.appendChild(input)

      return input
    }

    const checkFiles = (files, { maxUploadFiles, exts }) => {
      // check max uploaded files count
      if (maxUploadFiles !== null && files.length > maxUploadFiles) {
        return errors.maxUploadFiles
      }

      // check file exts
      let fileExtError = false
      files.forEach(file => {
        const fileExt = file.name.split('.').pop().toLowerCase()
        if (exts.indexOf(fileExt) === -1) {
          fileExtError = fileExt
        }
      })

      if (fileExtError) {
        return errors.exts.replace('%ext%', fileExtError)
      }

      return true
    }

    const handleFiles = (files, config) => {
      const check = checkFiles(files, config)
      if (check !== true) {
        config.onError(check)
        return
      }

      initializeProgress(files.length, config)
      config.onStart(files)
      let filesUploadDone = 0

      files.forEach((file, i) => {
        uploadFile(file, i, config).finally((response) => {
          filesUploadDone++
          if (filesUploadDone === files.length) {
            config.onDone(files)
          }
        })
      })
    }

    const init = (config, el) => {
      el.awVueUploader = {
        highlight (e) {
          if (el.awVueUploader.highlighted === false) {
            el.awVueUploader.highlighted = true
            if (checkFiles([...e.dataTransfer.files], config) === true) {
              el.classList.add(config.highlightClass)
            } else {
              el.classList.add(config.highlightErrorClass)
            }
          }
        },
        unhighlight (e) {
          if (el.awVueUploader.highlighted === true) {
            el.awVueUploader.highlighted = false
            el.classList.remove(config.highlightClass)
            el.classList.remove(config.highlightErrorClass)
          }
        },
        preventDefaults (e) {
          e.preventDefault()
          e.stopPropagation()
        },
        handleDrop (e) {
          handleFiles([...e.dataTransfer.files], config)
        },
        handleFile (e) {
          handleFiles([...el.awVueUploader.fileInput.files], config)
        },
        handleClick (e) {
          el.awVueUploader.fileInput.click()
        },
        fileInput: createForm(el, config),
        highlighted: false
      }

      ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
        el.addEventListener(eventName, el.awVueUploader.preventDefaults, false)
      })

      ;['dragenter', 'dragover'].forEach(eventName => {
        el.addEventListener(eventName, el.awVueUploader.highlight, false)
      })

      ;['dragleave', 'drop'].forEach(eventName => {
        el.addEventListener(eventName, el.awVueUploader.unhighlight, false)
      })

      el.addEventListener('drop', el.awVueUploader.handleDrop, false)
      el.addEventListener('click', el.awVueUploader.handleClick, false)
      el.awVueUploader.fileInput.addEventListener('change', el.awVueUploader.handleFile, false)
    }

    Vue.directive('uploader', {
      bind (el, binding) {
        init({ ...defaults, ...binding.value }, el)
      },
      inserted (el, binding) {
      },
      update (el, binding) {
      },
      unbind (el) {
        if (el.awVueUploader) {
          ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            el.removeEventListener(eventName, el.awVueUploader.preventDefaults, false)
          })

          ;['dragenter', 'dragover'].forEach(eventName => {
            el.removeEventListener(eventName, el.awVueUploader.highlight, false)
          })

          ;['dragleave', 'drop'].forEach(eventName => {
            el.removeEventListener(eventName, el.awVueUploader.unhighlight, false)
          })

          el.removeEventListener('drop', el.awVueUploader.handleDrop, false)
          el.removeEventListener('click', el.awVueUploader.handleClick, false)
          el.awVueUploader.fileInput.removeEventListener('change', el.awVueUploader.handleFile, false)

          delete el.awVueUploader
        }
      }
    })
  }
}
