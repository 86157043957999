import NetteAjax from '../nette/NetteAjax'

export default {
  install (Vue, options) {
    const click = (el, value) => {
      NetteAjax.ext('vue-router').data = value
    }

    Vue.directive('link', {
      bind (el, binding) {
        el.addEventListener('click', (e) => {
          click(el, binding.value)
        })
      },
      inserted (el, binding) {
        // console.log(el, binding, 'inserted')
      },
      update (el, binding) {
        // console.log(el, binding, 'update')
      },
      unbind (el) {
        // el.removeEventListener('click', click)
      }
    })
  }
}
