export default {
  install (Vue, options) {
    const colWidth = 606

    const computeHeightGalleries = ({ cols, gap, galleries, masonryOrdered, placeholders = 0 }, el) => {
      const heights = []
      const galleriesCols = []

      for (let i = 1; i <= cols; i++) {
        heights.push(0)
        galleriesCols.push([])
      }

      for (const k in galleries) {
        const firstPhoto = galleries[k].photos[0]
        galleries[k].index = parseInt(k)
        const minHeight = Math.min(...heights)
        for (let i = 0; i < cols; i++) {
          if (heights[i] === minHeight) {
            if (firstPhoto && firstPhoto.previews['photo-list'].width && firstPhoto.previews['photo-list'].height) {
              galleriesCols[i].push(k)
              if (firstPhoto.previews['photo-list'].width > colWidth) {
                heights[i] += Math.round(((colWidth / firstPhoto.previews['photo-list'].width) * firstPhoto.previews['photo-list'].height) + gap)
              } else {
                heights[i] += Math.round(firstPhoto.previews['photo-list'].height + gap)
              }
            } else {
              galleriesCols[i].push(k)
              heights[i] += 0
              console.log('uknown height?', galleries[k])
            }
            break
          }
        }
      }

      if (masonryOrdered && masonryOrdered.length === 0) {
        if (galleries.length < placeholders) {
          for (let ii = galleries.length; ii < placeholders; ii++) {
            const minHeight = Math.min(...heights)
            for (let i = 0; i < cols; i++) {
              if (heights[i] === minHeight) {
                galleriesCols[i].push(-1)
                heights[i] += ((colWidth / 1200) * 1200) + gap
                break
              }
            }
          }
        }

        let photoIndex = galleries.length
        for (let i = 0; i < cols; i++) {
          const col = []
          galleriesCols[i].forEach(k => {
            if (k === -1) {
              col.push({
                id: k * Math.random(),
                placeholder: true,
                index: photoIndex
              })
              photoIndex++
            } else {
              col.push(galleries[k])
            }
          })
          masonryOrdered.push(col)
        }
      }

      return Math.max(...heights)
    }

    const computeHeightPhotos = ({ cols, gap = 0, photos, masonryOrdered, placeholders = 0 }, el) => {
      const heights = []
      const galleriesCols = []

      for (let i = 1; i <= cols; i++) {
        heights.push(0)
        galleriesCols.push([])
      }

      for (const k in photos) {
        const firstPhoto = photos[k]
        firstPhoto.index = parseInt(k)
        const minHeight = Math.min(...heights)
        for (let i = 0; i < cols; i++) {
          if (heights[i] === minHeight) {
            if (firstPhoto.previews['photo-list'].width && firstPhoto.previews['photo-list'].height) {
              galleriesCols[i].push(k)
              heights[i] += Math.round(((colWidth / firstPhoto.previews['photo-list'].width) * firstPhoto.previews['photo-list'].height) + gap)
            } else {
              galleriesCols[i].push(k)
              heights[i] += 0 + gap
            }
            break
          }
        }
      }

      if (masonryOrdered && masonryOrdered.length === 0) {
        if (photos.length < placeholders) {
          for (let ii = photos.length; ii < placeholders; ii++) {
            const minHeight = Math.min(...heights)
            for (let i = 0; i < cols; i++) {
              if (heights[i] === minHeight) {
                galleriesCols[i].push(-1)
                heights[i] += ((colWidth / 1200) * 1200) + gap
                break
              }
            }
          }
        }

        let photoIndex = photos.length
        for (let i = 0; i < cols; i++) {
          const col = []
          galleriesCols[i].forEach(k => {
            if (k === -1) {
              col.push({
                id: k * Math.random(),
                placeholder: true,
                index: photoIndex
              })
              photoIndex++
            } else {
              col.push({ ...photos[k]/*, index: photoIndex */ })
            }
          })
          masonryOrdered.push(col)
        }
      }

      return Math.max(...heights)
    }

    Vue.directive('photoListHeight', {
      bind (el, binding) {
      },
      inserted (el, binding) {
        // el.style.height = computeHeightGalleries(binding.value, el) + 'px'
        if ('galleries' in binding.value) {
          computeHeightGalleries(binding.value, el)
        } else if ('photos' in binding.value) {
          computeHeightPhotos(binding.value, el)
        }
      },
      update (el, binding) {
        // el.style.height = computeHeightGalleries(binding.value, el) + 'px'
        if ('galleries' in binding.value) {
          computeHeightGalleries(binding.value, el)
        } else if ('photos' in binding.value) {
          computeHeightPhotos(binding.value, el)
        }
      }
    })
  }
}
