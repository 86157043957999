import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import './scss/app.scss'
// import 'bootstrap-vue/src/components/dropdown/index.scss'
import { DropdownPlugin } from 'bootstrap-vue'
import photoListHeight from './directives/photoListHeight'
import link from './directives/link'
import NetteAjax from './nette/NetteAjax'
import NetteForms from './directives/NetteForms'
import Uploader from './directives/Uploader'

Vue.use(VueAxios, axios)

// eslint-disable-next-line
// import bootstrap from 'nette-back-module/lib/bootstrap'
// eslint-disable-next-line
// bootstrap(Vue)

Vue.use(NetteAjax.ext('vue-router'), 'snippet--root')
Vue.use(NetteForms)
Vue.use(DropdownPlugin)
// Vue.use(BootstrapVue)
// Vue.use(IconsPlugin)
Vue.use(photoListHeight)
Vue.use(link)
Vue.use(Uploader)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#' + NetteAjax.ext('vue-router').rootElementId)
